<footer class="footer">
  <div class="content has-text-centered">
    <p>
      {{ 'footer.text' | translate:  { timezone: temporal.timeZone.timeZone } }}
    </p>
    <app-footer-patrons></app-footer-patrons>
    <br>
    <p>
      &copy; <span onclick="here_comes()">2018-{{ thisYear }} </span> <!-- space is needed inside of the span because angular -->
      <a href="https://dhgmedia.nl/?ref=oengus" target="_blank">DHG Media</a>
      - Oengus and all branding assets are owned by <a href="https://dhgmedia.nl/?ref=oengus" target="_blank">DHG Media</a>
      <!-- Using normal link here so it works properly -->
<!--      - <a href="/about#privacy-section">{{ 'aboutPage.privacy.title' | translate }}</a>-->
    </p>
  </div>
</footer>
